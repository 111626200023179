const unitAutocompleteSelect2 = (linePresta, value) => {
  const select = linePresta.querySelector(".sales_invoice_line_unit");
  $(select).val(value).trigger("change");
};

const vatAutocompleteSelect2 = (linePresta, value) => {
  const select = linePresta.querySelector(".sales_invoice_line_vat_rate");
  $(select).val(value).trigger("change");
};

export { unitAutocompleteSelect2, vatAutocompleteSelect2 };

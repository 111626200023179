import { Controller } from "@hotwired/stimulus";
import { hideElements, showElements } from "../utils/misc";
import $ from "jquery";

/**
 * Generic controller for multiple-step forms, whose fields are validated with HTML validations
 * All the steps of the form are loaded in the DOM by default, and toggled dynamically when clicking the next / previous button
 * We store the data of each step in localStorage (the localStorage item name should vary depending on the context, and is passed via a dedicated data attribute)
 */
export default class extends Controller {
  static targets = [
    "form",
    "formSection",
    "backRedirectBtn",
    "previousStepBtn",
    "nextStepBtn",
    "submitBtn",
    "submitLoader",
  ];
  static values = {
    currentStep: Number,
  };

  initialize() {
    this._groupSectionFields();

    document.addEventListener("multiStepForm:reset", () => {
      this.reinitializeForm();
    });

    this.currentStepValue = 0;
    this.localStorageItemName = this.element.dataset.localStorageItem;
    if (!this.localStorageItemName) {
      return;
    }

    if (localStorage.getItem(this.localStorageItemName)) {
      this._restoreFormState();
    } else {
      this.resetForm();
    }
  }

  nextStep() {
    const currentStep = this.currentStepValue;
    $(this.formTarget)
      .parsley()
      .whenValidate({ group: "group-" + currentStep })
      .done(() => {
        if (this.currentStepValue === 0) {
          this._toggleButtons(this._previousStepButtons());
        }
        this._saveFormState();
        this._formSectionFor(currentStep).classList.remove("current");
        this._formSectionFor(currentStep + 1).classList.add("current");
        this.currentStepValue += 1;
        if (this.currentStepValue === this.formSectionTargets.length - 1) {
          this._toggleButtons([this.nextStepBtnTarget, this.submitBtnTarget]);
        }
      });
  }

  previousStep() {
    if (this.currentStepValue === 1) {
      this._toggleButtons(this._previousStepButtons());
    }
    if (this.currentStepValue === this.formSectionTargets.length - 1) {
      this._toggleButtons([this.nextStepBtnTarget, this.submitBtnTarget]);
    }
    this._formSectionFor(this.currentStepValue).classList.remove("current");
    this._formSectionFor(this.currentStepValue - 1).classList.add("current");
    this.currentStepValue -= 1;
  }

  reinitializeForm() {
    const elementsToHide = [this.previousStepBtnTarget, this.submitBtnTarget];
    hideElements(elementsToHide);

    let elementsToDisplay = [this.nextStepBtnTarget];
    if (this.hasBackRedirectBtnTarget) {
      elementsToDisplay = [...elementsToDisplay, this.backRedirectBtnTarget];
    }
    showElements(elementsToDisplay);

    this._formSectionFor(this.currentStepValue).classList.remove("current");
    this._formSectionFor(0).classList.add("current");
    this.currentStepValue = 0;
  }

  onSubmit() {
    if ($(this.formTarget).parsley().isValid()) {
      this._saveFormState();
      if (this.hasSubmitLoaderTarget) {
        this.formTarget.classList.add("hidden");
        this.submitLoaderTarget.classList.remove("hidden");
      }
    }
  }

  onSubmitCompleted() {
    if (this.hasSubmitLoaderTarget) {
      this.formTarget.classList.remove("hidden");
      this.submitLoaderTarget.classList.add("hidden");
    }
  }

  _groupSectionFields() {
    // Allows to validate fields of a given form-section together but independently of other section fields
    this.formSectionTargets.forEach((section, index) => {
      $(section).find(":input").attr("data-parsley-group", `group-${index}`);
    });
  }

  _formSectionFor(step) {
    return this.formSectionTargets.find((target, index) => index === step);
  }

  resetForm() {
    localStorage.setItem(this.localStorageItemName, JSON.stringify({}));
  }

  _restoreFormState() {
    const storedFormData = JSON.parse(localStorage.getItem(this.localStorageItemName));
    [...this.formTarget.elements]
      .filter((element) => element.name !== "authenticity_token")
      .forEach((element) => {
        if (storedFormData[element.name]) {
          element.value = storedFormData[element.name];
        }
      });
  }

  _saveFormState() {
    if (!this.localStorageItemName) {
      return;
    }

    const storedFormData = JSON.parse(localStorage.getItem(this.localStorageItemName));
    const currentFormData = new FormData(this.formTarget);
    [...currentFormData].forEach(([key, value]) => {
      storedFormData[key] = value;
    });
    localStorage.setItem(this.localStorageItemName, JSON.stringify(storedFormData));
  }

  _toggleButtons(buttons) {
    buttons.forEach((button) => button.classList.toggle("hidden"));
  }

  _previousStepButtons() {
    if (this.hasBackRedirectBtnTarget) {
      return [this.backRedirectBtnTarget, this.previousStepBtnTarget];
    } else {
      return [this.previousStepBtnTarget];
    }
  }
}

import { flatpickrAltInput, isFlatpickrInput } from "./date_utils";

export const isSelect2Input = (input) => input.dataset.controller === "select2";

export const selectedOptionFor = (selectInput) => selectInput.options[selectInput.selectedIndex];

export const selectOptionFor = (selectInput, optionValue) => $(selectInput).val(optionValue).trigger("change");

export const select2ContainerFor = (selectInput) => selectInput.nextElementSibling;

export const isCheckbox = (input) => input.type === "checkbox";

export const isEscapeKey = (keyCode) => keyCode === 27;

export const isEnterKey = (keyCode) => keyCode === 13;

export const inputToValidate = (input) => (isFlatpickrInput(input) ? flatpickrAltInput(input) : input);

export const numberOfItems = (ids) => {
  if (ids.value === "") {
    return 0;
  } else {
    return ids.value.split(",").length;
  }
};

export const inputValueById = (id) => {
  return inputValueBySelector(`#${id}`);
};

export const inputValueBySelector = (selector) => {
  let value;
  const input = document.querySelector(selector);
  if (input) {
    value = input.value;
  }
  return value;
};

export const emptySelect = (select) => {
  select.length = 0;
};

export const addNewOptionToSelect = (select, option) => {
  select.add(new Option(option.text, option.value));
};

export const sortSelectAlphabetically = (select) => {
  return Array.from(select.options)
    .map((option) => ({ text: option.innerText, value: option.value }))
    .sort((a, b) => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });
};

export const fillFromSortedSelect = (select) => {
  const sortedSelect = sortSelectAlphabetically(select);

  emptySelect(select);
  sortedSelect.forEach((option) => {
    addNewOptionToSelect(select, option);
  });
};

export const elementsNamesFrom = (elements) => {
  return Array.from(elements).map((element) => element.name);
};

export const focusField = (field) => {
  field.focus();
};

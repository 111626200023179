import { initDatatable } from "./datatable_factory";
import { dataTableFunctions } from "./datatable_functions";

const initSalesToVerifyDatatable = (datatableElement) => {
  const columns = datatableColumns();
  const columnIndexes = dataTableFunctions().columnIndexes({ columns: columns });

  const payload = {
    datatableElement: datatableElement,
    orderColumns: [[columnIndexes[columnNames.file_name], "desc"]],
    columns: datatableColumns(),
    onCreatedRow: onCreatedRow(),
  };
  initDatatable(payload);
};

const columnNames = {
  file_name: "file_name",
};

const datatableColumns = () => {
  return [
    {
      data: columnNames.file_name,
      orderable: true,
      className: "file-column",
    },
  ];
};

const onCreatedRow = () => {
  return (row, data) => {
    row.setAttribute("data-toggle", "modal");
    row.setAttribute("data-target", "#upload_single_sales_modal");
    row.setAttribute("data-url", data.url);
  };
};

export { initSalesToVerifyDatatable };

import { Controller } from "@hotwired/stimulus";
import { closeSidePanel } from "../../utils/misc";
import { removeTableRow } from "../../components/tables/remove_table_row";

export default class extends Controller {
  afterAjax(event) {
    removeTableRow(event.currentTarget.dataset.deletedInvoiceId);
    closeSidePanel();
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element", "input"];
  static values = {
    bindInputId: String,
    visibleOn: String,
  };

  connect() {
    const input = document.getElementById(this.bindInputIdValue);
    this._manageElementDisplay(input.value);
    input.addEventListener("input", () => {
      this._manageElementDisplay(input.value);
    });
  }

  _manageElementDisplay(bindInputValue) {
    if (this.visibleOnValue === bindInputValue) {
      this._showElement();
    } else {
      this._hideElement();
    }
  }

  _showElement() {
    this.elementTarget.classList.remove("hidden");
    if (this.hasInputTarget) {
      this.inputTarget.disabled = false;
    }
  }

  _hideElement() {
    this.elementTarget.classList.add("hidden");
    if (this.hasInputTarget) {
      this.inputTarget.disabled = true;
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import { flashAlert } from "../../components/flash_alert";
import { removeTableRow } from "../../components/tables/remove_table_row";
import { handleHttpResponse } from "../../utils/http_utils";

export default class extends Controller {
  static targets = ["problematicPurchase"];

  purchaseUpdated(event) {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        const purchaseInvoice = JSON.parse(response).purchase_invoices[0];
        if (this.hasProblematicPurchaseTarget && this._isProblematicPurchaseResolved(purchaseInvoice)) {
          flashAlert("Bravo ! Vous avez validé cet achat");
          removeTableRow(purchaseInvoice.id);
        } else {
          flashAlert("Informations mises à jour");
        }
      },
    });
  }

  _isProblematicPurchaseResolved(purchaseInvoice) {
    return purchaseInvoice.error.length === 0;
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["catalogItemInput"];

  selectCatalogItem(e) {
    const input = e.currentTarget;
    const selectedOption = input.options[input.selectedIndex];
    const associatedCatalogItem =
      selectedOption.dataset.catalogItemId === undefined ? "" : selectedOption.dataset.catalogItemId;
    this.catalogItemInputTarget.value = associatedCatalogItem;
  }
}

import { Controller } from "@hotwired/stimulus";
import { isVatConsistent } from "../../purchase_invoice/control_vat_consistency";
import { launchASweetAlert } from "../../components/init_sweet_alert";
import { STANDARD_VAT_RATE } from "../../utils/constants";
import { toggleClass } from "../../utils/misc";
import { isInputFilled } from "../../react_components/design_system/input/utils";

export default class extends Controller {
  static targets = ["amount", "vat", "vatAlert"];

  toggleVatAlert(e) {
    const vatInput = e.target.value;
    toggleClass(this.vatAlertTarget, "hidden", isInputFilled(vatInput));
  }

  // TODO(David Michel): replace w/ a custom parsley validator if possible
  validateSubmission(e) {
    if (!isVatConsistent(this.vatTarget.value, this.amountTarget.value)) {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.vatTarget.classList.add("border-red");
      launchASweetAlert({
        title: `Le montant de TVA renseigné semble incohérent car supérieur à ${STANDARD_VAT_RATE * 100}%.`,
      });
    }
  }
}

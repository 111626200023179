import { Controller } from "@hotwired/stimulus";
import { interpolate } from "../../utils/string_utils";
import { numberToCurrency } from "../../utils/number_utils";
import { handleHttpResponse } from "../../utils/http_utils";
import { closeModal, toggleClass } from "../../utils/misc";
import { matchingModalFunctions } from "../../matching_modal/matching_modal_functions";
import { errorMessages, notifyError } from "../../components/flash_alert";
import { i18nTranslations } from "../../utils/i18n_utils";
import MatchingBalance from "../../matching_modal/matching_balance";

export default class extends Controller {
  static targets = [
    "bankTransactionInput",
    "purchaseInvoiceInput",
    "salesInvoiceInput",
    "summaryContainer",
    "summaryTemplate",
    "matchingBalanceAmount",
    "balanceBadgeContainer",
    "submitBtn",
    "chosenCandidatesList",
    "selectedForMatchingLineTemplate",
    "emptySelectedOperations",
    "operationSelectedForMatching",
    "errorContainer",
  ];

  connect() {
    this._toggleEmptySelectedOperations();
    this._updateSummary();
  }

  removeFromMatchingSelection(e) {
    if (!matchingModalFunctions().targetShouldToggleMatchingSelection(e.target)) {
      return;
    }

    const line = e.currentTarget;
    line.remove();
    const operationId = matchingModalFunctions().descriptionLine(line).dataset.operationId;
    const candidatesController = this._candidatesControllerFor(line);
    candidatesController.removeFromMatchingSelection(operationId);
    this._toggleEmptySelectedOperations();
    this._updateSummary();
  }

  addToMatchingSelection(transactionElement) {
    const lineTemplate = interpolate(this.selectedForMatchingLineTemplateTarget, {
      lineItem: transactionElement.outerHTML,
    });
    this.chosenCandidatesListTarget.insertAdjacentHTML("beforeend", lineTemplate);
    this._toggleEmptySelectedOperations();
    this._updateSummary();
  }

  onMatchingUpdated() {
    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        const data = JSON.parse(response);
        const event = new CustomEvent("matching:updated", { detail: data });
        document.dispatchEvent(event);
        closeModal("matching-modal");
      },
      onError: (response, status) => {
        if (status === 422) {
          const parsedResponse = JSON.parse(response);
          this.errorContainerTarget.innerHTML = i18nTranslations().t(parsedResponse.error, {
            scope: "manual_matching.errors",
          });
        } else {
          notifyError(errorMessages(status));
        }
      },
    });
  }

  _updateSummary() {
    const balance = new MatchingBalance(this.bankTransactions, this.purchaseInvoices, this.salesInvoices);
    this._updateSummaryTemplate(balance);
    this._updateMatchingBalance(balance.balanceAmountClass, balance.badge);
    this._updateSubmitButton(balance.isBalanceAcceptable);
  }

  get bankTransactions() {
    return this._formattedValues(this.bankTransactionInputTargets);
  }

  get purchaseInvoices() {
    return this._formattedValues(this.purchaseInvoiceInputTargets);
  }

  get salesInvoices() {
    return this._formattedValues(this.salesInvoiceInputTargets);
  }

  _updateSummaryTemplate(balance) {
    const template = interpolate(this.summaryTemplateTarget, {
      selectedBankTransactionsCounter: this.bankTransactionInputTargets.length,
      selectedBankTransactionsAmount: this._formattedSummaryAmount(balance.bankTransactionsAmount, balance),
      selectedPurchaseInvoicesCounter: this.purchaseInvoiceInputTargets.length,
      selectedPurchaseInvoicesAmount: this._formattedSummaryAmount(-balance.purchaseInvoicesAmount, balance),
      selectedSalesInvoicesCounter: this.salesInvoiceInputTargets.length,
      selectedSalesInvoicesAmount: this._formattedSummaryAmount(balance.salesInvoicesAmount, balance),
      balanceAmount: this._formattedSummaryAmount(balance.balanceAmount, balance),
    });
    this.summaryContainerTarget.innerHTML = template;
  }

  _updateMatchingBalance(balanceAmountClass, balanceBadge) {
    this.matchingBalanceAmountTarget.classList.add(balanceAmountClass);
    this.balanceBadgeContainerTarget.innerHTML = balanceBadge;
    this.errorContainerTarget.innerHTML = "";
  }

  _updateSubmitButton(isBalanceValid) {
    this.submitBtnTarget.disabled = !isBalanceValid;
  }

  _formattedValues(inputs) {
    return inputs.map((input) => JSON.parse(input.dataset.values));
  }

  _toggleEmptySelectedOperations() {
    toggleClass(this.emptySelectedOperationsTarget, "hidden", this.operationSelectedForMatchingTargets.length === 0);
  }

  _candidatesControllerFor(line) {
    const controllerName = "matching-modal--candidates-list";
    const operationType = matchingModalFunctions().descriptionLine(line).dataset.operationType;
    const controllerElement = document.querySelector(
      `.candidates-list-container[data-candidate-type="${operationType}"]`
    );
    return this.application.getControllerForElementAndIdentifier(controllerElement, controllerName);
  }

  _formattedSummaryAmount(amount, balance) {
    return amount === null ? "-" : numberToCurrency(amount, { currency: balance.currency });
  }
}

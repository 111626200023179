import { employerSalaryCost } from "../../utils/business_utils";
import { currencyToNumber, numberToCurrency } from "../../utils/number_utils";

const computeSalaryTotalCost = () => {
  const netAmountInput = document.getElementById("social_gross_salary_amount");
  const totalAmountDiv = document.getElementById("salary_total_cost");
  if (netAmountInput && totalAmountDiv) {
    let netAmount = parseInt(currencyToNumber(netAmountInput.value));
    totalAmountDiv.innerHTML = totalSalaryFormatted(netAmount);

    netAmountInput.addEventListener("input", () => {
      netAmount = parseInt(netAmountInput.value);
      totalAmountDiv.innerHTML = totalSalaryFormatted(netAmount);
    });
  }
};

const totalSalaryFormatted = (netAmount) => {
  if (!netAmount) {
    return "";
  }
  return numberToCurrency(employerSalaryCost(netAmount));
};

export { computeSalaryTotalCost };

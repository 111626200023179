import { Controller } from "@hotwired/stimulus";
import swal from "sweetalert";
import { displayFilePreview } from "../../profiles/profile_file_upload";
import { isSwalConfirmed } from "../../utils/misc";

/**
 * Used in the documents step in the company creation workflow to handle specific logic linked to uploads
 * Note that all instances of this controller on the documents page are reinvoked each time a document is saved
 */
export default class extends Controller {
  static targets = ["nature", "document", "natureMissingMessage", "fileCardContainer"];
  static values = { uploadedId: Number };

  initialize() {
    if (this.hasNatureTarget) {
      const uploaded = this.hasUploadedIdValue && !isNaN(this.uploadedIdValue);
      const natureSelect = $(`#${this.natureTarget.id}`);
      // The nature select is disabled if a document has already been uploaded
      // If the nature of the document needs to be changed, the document will need to be reuploaded
      natureSelect.prop("disabled", !!this.natureTarget.value && uploaded);
      natureSelect.on("select2:select", (e) => {
        this.onNatureUpdate(e);
      });
    }
  }

  onNatureUpdate(e) {
    if (e.target.value && this.documentTarget.value) {
      this.natureMissingMessageTarget.classList.add("hidden");
      $(`#${this.natureTarget.id} + .select2-container`).removeClass("invalid");
      // Automatically submit the documents form when the nature of a newly uploaded document has been set
      this._submitForm(e.target);
    }
  }

  onDocumentUpload(e) {
    if (!e.target.value) {
      return;
    }

    if (e.params.confirmMessage) {
      this._submitDocumentWithConfirmation(e.params.confirmMessage, e.target);
      return;
    }

    this._submitDocumentWithoutConfirmation(e.target);
  }

  _submitForm(fileInput) {
    Rails.fire(fileInput.form, "submit");
  }

  _submitDocumentWithConfirmation(confirmMessage, fileInput) {
    swal({
      text: confirmMessage,
      icon: "warning",
      buttons: { non: true, oui: true },
      className: "custom-swal-modal",
    }).then((value) => {
      if (isSwalConfirmed(value)) {
        this._submitDocumentWithoutConfirmation(fileInput);
      } else {
        fileInput.value = null;
        displayFilePreview(this.fileCardContainerTarget, fileInput);
      }
    });
  }

  _submitDocumentWithoutConfirmation(fileInput) {
    if (this.hasNatureTarget && !this.natureTarget.value) {
      this.natureMissingMessageTarget.classList.remove("hidden");
      $(`#${this.natureTarget.id} + .select2-container`).addClass("invalid");
    } else {
      this._submitForm(fileInput);
    }
  }
}

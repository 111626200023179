import { addMonths, addYears, isDate, parse, sub } from "date-fns";
import { APPLICATION_TIME_ZONE } from "./constants";

export const isDateValid = (date) => {
  return Object.prototype.toString.call(date) === "[object Date]" && !Number.isNaN(date.getTime());
};

export const twoDigitsMonth = (date) => {
  return ("0" + (date.getMonth() + 1)).slice(-2);
};

export const nextMonth = () => addMonths(new Date(), 1);

export const oneYearAfter = (date) => addYears(date, 1);

export const convertFrenchDateToISO = (date) => {
  if (!date || date.length !== 10 || date.split("/").length !== 3) {
    return date;
  }

  const [day, month, year] = date.split("/");
  return new Date(year, month - 1, day);
};

export const isFlatpickrInput = (input) => input.classList.contains("flatpickr-input");

// Centralize fetching mechanism for flatpickr alt input (which is the real input's nextElementSibling, and doesn't have a custom class name)
export const flatpickrAltInput = (input) => input.nextElementSibling;

export const fillFlatpickrInput = (flatpickrInput, date) => {
  const altInputToPrefill = flatpickrAltInput(flatpickrInput);
  // on mobile flatPickr is natively disabled and turn the input into a date input https://flatpickr.js.org/mobile-support/
  const dateToPrefill = altInputToPrefill.type === "date" ? date : formattedDate(date);
  flatpickrInput.value = date;
  altInputToPrefill.value = dateToPrefill;
  // needed to execute the validations
  flatpickrInput.dispatchEvent(new Event("input"));
};

export const eigtheenYearsAgo = sub(new Date(), { years: 18 });

export const parseDateString = (value, originalValue) =>
  isDate(originalValue) ? originalValue : parse(originalValue, "yyyy-MM-dd", new Date());

const computeDateStringDetails = (dateString) => {
  const date = new Date(dateString);

  return {
    day: new Intl.DateTimeFormat("fr-Fr", { day: "numeric" }).format(date),
    month: new Intl.DateTimeFormat("fr-Fr", { month: "numeric" }).format(date),
    year: new Intl.DateTimeFormat("fr-Fr", { year: "numeric" }).format(date),
  };
};

export const formattedDate = (date) => {
  if (!date) {
    return "";
  }

  const dateDetails = computeDateStringDetails(date.toString());
  const month = dateDetails.month.padStart(2, "0");
  const day = dateDetails.day.padStart(2, "0");

  return `${day}/${month}/${dateDetails.year}`;
};

export const isFrenchFormattedDate = (value) => value.match(/\d{2}\/\d{2}\/\d{4}/);

export const jsFormattedDate = (date) => {
  const currentDate = new Date(date);
  let month = "" + (currentDate.getMonth() + 1),
    day = "" + currentDate.getDate(),
    year = currentDate.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const dateWithAbbrMonth = (date) => {
  return new Date(date).toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: APPLICATION_TIME_ZONE,
  });
};

export const daysAgo = (numberOfDays) => {
  const date = new Date();
  date.setDate(date.getDate() - numberOfDays);
  return date;
};

export const addDaysToDate = (date, numberOfDays) => {
  if (isNaN(date)) {
    return new Date();
  }

  date.setDate(date.getDate() + numberOfDays);
  return date;
};

export const lastDateOfMonth = (month, year) => {
  return new Date(year, month + 1, 0, 12);
};

export const diffDays = (startDate, endDate) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  return Math.round(Math.abs((startDate.setHours(0, 0, 0, 0) - endDate.setHours(0, 0, 0, 0)) / oneDay));
};

export const isLeapYear = (year) => new Date(year, 1, 29).getDate() === 29;

export const dynamicDateRange = (dates) => {
  const defaultMaxDate = jsFormattedDate(new Date(9999, 11, 31));

  if (!dates[0]) {
    return [jsFormattedDate(new Date(null)), dates[1] || defaultMaxDate];
  }
  if (!dates[1]) {
    return [dates[0], defaultMaxDate];
  }

  return dates;
};

export const fullMonthAndYear = (date) => {
  return date.toLocaleDateString("fr-FR", { month: "long", year: "numeric" });
};

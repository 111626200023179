import { Controller } from "@hotwired/stimulus";
import { initSalesToVerifyDatatable } from "../../datatables/sales_invoices_to_verify_datatable";
import { isDatatableUninitialized } from "../../datatables/datatable_functions";

export default class extends Controller {
  initialize() {
    if (isDatatableUninitialized(this.element)) {
      initSalesToVerifyDatatable(this.element);
    }
  }
}

import $ from "jquery";
import { initDatatable } from "./datatable_factory";
import { dataTableFunctions } from "./datatable_functions";
import { compiledTemplate, interpolate, setTemplatePartial } from "../utils/string_utils";
import { numberToCurrency } from "../utils/number_utils";
import { dateWithAbbrMonth, formattedDate } from "../utils/date_utils";
import { htmlTextContent } from "../utils/misc";
import { registerNumberToCurrencyHelper } from "../utils/handlebars_helpers";
import { inputValueById } from "../utils/inputs_utils";
import { amountFilterRange, computePeriodDateRange } from "./datatable_filters";

const initPurchasesDatatable = (datatableElement) => {
  const columns = datatableColumns(datatableElement);
  const columnIndexes = dataTableFunctions().columnIndexes({ columns: columns });

  registerNumberToCurrencyHelper();

  const payload = {
    datatableElement: datatableElement,
    orderColumns: [[columnIndexes[columnNames.date], "desc"]],
    columns: datatableColumns(),
    setRequestData: setRequestData(),
    onComplete: onComplete(datatableElement),
    onDraw: onDraw(),
    onCreatedRow: onCreatedRow(),
    exportable: true,
  };
  initDatatable(payload);
};

const updatePurchaseInvoicesTableRows = (purchaseInvoices) => {
  document.dispatchEvent(new CustomEvent("datatable:reloadRows", { detail: purchaseInvoices }));
  document.dispatchEvent(new CustomEvent("datatable:refreshSidePanel"));
};

const columnNames = {
  input: "input",
  id: "id",
  date: "date",
  supplier: "supplier",
  category: "category",
  amount: "amount",
  vat: "vat",
  status: "status",
};

const setRequestData = () => {
  return (data) => {
    const columnIndexes = dataTableFunctions().columnIndexes(data);

    dataTableFunctions().setColumnFilter(data, columnIndexes[columnNames.category], inputValueById("category-filter"));
    dataTableFunctions().setColumnFilter(data, columnIndexes[columnNames.amount], amountFilter());

    data.vat_rates = vatRatesFilter();
    data.period = computePeriodDateRange();
    data.tag = inputValueById("tag-filter");
    data.currency = inputValueById("currency-filter");
    data.purchases_search = document.getElementById("purchases-search").value;

    if (document.getElementById("global-search-filtered-id").checked) {
      data.global_search_filtered_id = document.getElementById("global-search-filtered-id").value;
    }
  };
};

const vatRatesFilter = () => {
  const selectedVatInputs = document.querySelectorAll("input[name='vat-filter']:checked");
  return Array.from(selectedVatInputs).map((vatInput) => vatInput.value);
};

const amountFilter = () => {
  const rangeStartFilter = document.getElementById("purchase-amount-min");
  if (rangeStartFilter) {
    return amountFilterRange(
      {
        amountEqInput: document.getElementById("purchase-amount-eq"),
        amountLtInput: document.getElementById("purchase-amount-lt"),
        amountGtInput: document.getElementById("purchase-amount-gt"),
        amountBwInput: document.getElementById("purchase-amount-bw"),
      },
      document.getElementById("purchase-amount-min").value,
      document.getElementById("purchase-amount-max").value
    );
  } else {
    return "";
  }
};

const datatableColumns = () => {
  return [
    {
      data: columnNames.input,
      orderable: false,
      className: "select-column checkbox-element",
      render: (data, type, row) => {
        return interpolate(document.getElementById("select-item-checkbox-template"), {
          id: row.id,
        });
      },
    },
    {
      data: columnNames.supplier,
      orderable: true,
      searchable: true,
      className: "supplier-column",
      render: (data, type, row) => {
        const { filename, processing } = row.accounting_document;
        const awaiting_ocr_response = processing === true && row.incomplete;

        setTemplatePartial("noteIcon", document.getElementById("note-icon-template"));

        return compiledTemplate(document.getElementById("purchase-invoice-supplier-column-template"), {
          supplier: row.supplier,
          awaiting_ocr_response: awaiting_ocr_response,
          displayed_content: row.supplier ? row.supplier : filename,
          note: row.note,
          id: row.id,
        });
      },
    },
    {
      data: columnNames.category,
      orderable: true,
      className: "category-column",
      render: (data, type, row) => categoryCellContent(row),
    },
    {
      data: columnNames.date,
      orderable: true,
      className: "date-column",
      render: (data) => {
        if (!data) {
          return "";
        }
        return dateWithAbbrMonth(data);
      },
    },
    {
      data: columnNames.status,
      orderable: false,
      className: "status-column",
      render: (data, type, row) => statusCellContent(row),
    },
    {
      data: columnNames.amount,
      orderable: true,
      className: "amount-column",
      render: (data, type, row) => amountCellContent(row),
    },
    {
      data: columnNames.vat,
      orderable: false,
      className: "vat-column",
      visible: false,
      render: (data, type, row) => {
        // NOTE(Jonathan Jalal): If we don't override the #render function for a given column,
        // then by default the Datatable JS library is going to look in the row payload for a key corresponding to the column's name
        // If the key is not found, then the following error is returned : https://datatables.net/manual/tech-notes/4
        // Here, as the VAT is returned in the row.amounts key and not in row.vat, we still need to override #render even if we make the column not visible
        return row.amounts.vat;
      },
    },
    { data: columnNames.id, orderable: false, className: "ids-column", visible: false },
  ];
};

const onComplete = (datatableElement) => {
  return () => {
    const search = document.getElementById("purchases-search");
    dataTableFunctions().manageSearchBar(search, datatableElement.id);
    manualMatchingResponse();
  };
};

const manualMatchingResponse = () => {
  document.addEventListener("matching:updated", (e) => {
    const purchaseInvoices = e.detail.purchase_invoices;
    updatePurchaseInvoicesTableRows(purchaseInvoices);
  });
};

const onDraw = () => {
  return () => {
    dataTableFunctions().manageFilteredSelection();
    window.dispatchEvent(new Event("initTooltip"));
  };
};

const onCreatedRow = () => {
  return (row, data) => {
    const { accounted, processed } = data;
    $(row).addClass(`accounted-${accounted}`);
    $(row).addClass(`processed-${processed}`);
  };
};

const categoryCellContent = (data) => {
  const { id, date, supplier, category, amounts } = data;
  const { gross_amount } = amounts;
  const { id: categoryId, name: categoryName, supra_category, supra_icon, locked_status: lockedStatus } = category;
  const lockedDescription = dataTableFunctions().lockedStatusDescription(lockedStatus);
  const locked = lockedStatus || data.accounted || data.processed;

  setTemplatePartial("lockedCategory", document.getElementById("locked-category-template"));
  setTemplatePartial("displayedCategory", document.getElementById("displayed-category-template"));
  return compiledTemplate(document.getElementById("category-column-template"), {
    item: {
      id: id,
      date: formattedDate(date),
      description: supplier,
      amount: numberToCurrency(gross_amount),
    },
    category: {
      id: categoryId,
      category_name: categoryName,
      supra_category: supra_category,
      supra_icon: supra_icon,
      title: htmlTextContent(categoryName),
      locked: { status: locked, description: lockedDescription },
    },
  });
};

const statusCellContent = (data) => {
  const { paid, is_current_account, shareholder_account_full_name, shareholder_account_abbreviated_name } = data.status;

  return compiledTemplate(document.getElementById("purchase-invoice-status-column-template"), {
    paid: paid,
    is_current_account: is_current_account,
    shareholder_account_full_name: shareholder_account_full_name,
    shareholder_account_abbreviated_name: shareholder_account_abbreviated_name,
  });
};

const amountCellContent = (data) => {
  const { amounts } = data;
  const { gross_amount, original_amount, currency, foreign_currency, vat, deductible_vat } = amounts;

  setTemplatePartial("amountWithConversion", document.getElementById("amount-with-conversion-template"));
  return compiledTemplate(document.getElementById("purchase-invoice-amount-column-template"), {
    gross_amount: gross_amount,
    original_amount: original_amount,
    currency: currency,
    foreign_currency_amount: foreign_currency,
    vat: vat,
    deductible_vat: deductible_vat,
    distinct_deductible_vat: vat !== deductible_vat,
  });
};

export { initPurchasesDatatable, updatePurchaseInvoicesTableRows, amountCellContent };

import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import "../../../vendor/assets/javascripts/parsley/fr";
import {
  initDateAfterOrEqualToValidator,
  initExcludedFromValidator,
  initRequiredIfValidator,
  standardErrorsContainer,
} from "../utils/parsley_validators_utils";

/**
 * Generic controller for form validation with the parsleyjs library
 * Here we set/override configuration options applicable to any such form
 */
export default class extends Controller {
  static targets = ["trixEditor"];

  static values = {
    customValidators: Array,
  };

  initialize() {
    $(this.element).parsley({
      ...standardErrorsContainer(),
    });
    this._setFieldValidationTrigger();
    this._initCustomValidators();

    if (this.hasTrixEditorTarget) {
      this._setTrixEditorValidation();
    }
  }

  disconnect() {
    $(this.element).parsley().destroy();
  }

  validate() {
    $(this.element).parsley().validate();
  }

  _setFieldValidationTrigger() {
    [...this.element.elements]
      .filter((element) => !element.dataset.parsleyTrigger)
      .forEach((element) => {
        element.dataset.parsleyTrigger = "blur";
      });
  }

  _setTrixEditorValidation() {
    const trixToolbarLinkButton = 'input[name="href"]';
    const excludedElements = this.element.dataset.parsleyExcluded;

    this.element.dataset.parsleyExcluded = [excludedElements, trixToolbarLinkButton].join(",");
  }

  _initCustomValidators() {
    this.customValidatorsValue.forEach((customValidator) => {
      if (!window.Parsley.hasValidator(customValidator)) {
        this._initCustomValidator(customValidator);
      }
    });
  }

  _initCustomValidator(customValidator) {
    if (customValidator === "dgte") {
      initDateAfterOrEqualToValidator();
    } else if (customValidator === "requiredIf") {
      initRequiredIfValidator();
    } else if (customValidator === "excludedFrom") {
      initExcludedFromValidator();
    }
  }
}
